import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Routes from './routes'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueRouter)
Vue.use(VueAxios, axios)

/* Vue.prototype.$http = axios
Vue.prototype.$axios = axios
Vue.config.productionTip = false */

const router = new VueRouter({
  mode: "history",
  routes: Routes,
  scrollBehavior(){
    return { x: 0, y: 0}
  }
}) 

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
