<template>
    <div>

        <section id="saas_two_banner" class="saas_two_banner_section relative-position" style="height: 100vh;">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="s2-banner_area relative-position">
                            <div class="s2-banner_content saas2-headline pera-content">
                                <span class="s2-tilte_tag">Creative CRM App</span>
                                <h1>Smartsites
                                    <!-- <span>CRM</span> -->
                                </h1>
                                <p>Un logiciel de gestion de la relation client idéal pour les petites et moyennes entreprises</p>
                                <div class="banner_btn">
                                    <router-link target="blank" to="/contact"><i class="fas fa-cloud-download-alt"></i> Essai gratuit</router-link>
                                </div>
                                <!-- <span>No credit card required *</span> -->
                            </div>
                            <div class="banner_mockup">
                                <img src="assets/images/home.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="s2-banner_shape1 position-absolute" data-parallax='{"y" : 100}'><img src="assets/images/banner/b-shape4.png" alt=""></div>
            <div class="s2-banner_shape2 position-absolute"><img src="assets/images/banner/b-shape3.png" alt=""></div>
            <div class="s2-banner_shape3 position-absolute"><img src="assets/images/banner/b-shape2.png" alt=""></div>
        </section>

        <AboutContent />
        
        <section id="saas_two_feature" class="saas_two_feature_section">
            <div class="container">
                <div class="s2-feature_content">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 wow fadeFromUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                            <div class="s2-feature_left">
                                <div class="s2-feature_text saas2-headline pera-content">
                                    <span class="feature_tag">Faites-nous confiance </span>
                                    <h2>
                                        Faites croître votre entreprise grâce à des ressources dédiées comme notre Base de connaissance, 
                                        notre Communauté.
                                    </h2>
                                    <div class="saas_btn">
                                        <router-link target="blank" to="/contact"><i class="fas fa-clipboard-list"></i> Essai gratuit</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /feature left content -->
                        <div class="col-lg-6 col-md-12">
                            <div class="s2-feature_right">
                                <div class="s2-feature_list s2-grean wow fadeFromUp" data-wow-delay="0ms" data-wow-duration="1000ms">
                                    <div class="s2-feature_icon text-center relative-position">
                                        <i class="fab fa-codepen"></i>
                                    </div>
                                    <div class="s2-feature_text_box saas2-headline pera-content">
                                        <h3>Plus de 20 intégrations</h3>
                                        <p>Découvrez l'application et améliorez votre expérience de Smartsites en vous connectant facilement à d'autres services.</p>
                                    </div>
                                </div>
                                <!-- list item -->
                                <div class="s2-feature_list s2-purple wow fadeFromUp" data-wow-delay="100ms" data-wow-duration="1000ms">
                                    <div class="s2-feature_icon text-center relative-position">
                                        <i class="fas fa-pen-square"></i>
                                    </div>
                                    <div class="s2-feature_text_box saas2-headline pera-content">
                                        <h3>Design</h3>
                                        <p>Un CRM visuel, fonctionnel et bénéficiant d'une interface utilisateur claire, qui ferait la fierté de n'importe quel designer ou illustrateur.</p>
                                    </div>
                                </div>
                                <!-- list item -->
                                <div class="s2-feature_list s2-pink wow fadeFromUp" data-wow-delay="200ms" data-wow-duration="1000ms">
                                    <div class="s2-feature_icon text-center relative-position">
                                        <i class="fas fa-headphones"></i>
                                    </div>
                                    <div class="s2-feature_text_box saas2-headline pera-content">
                                        <h3>Assistance 24h/24 et 7j/7 par e-mail et chat</h3>
                                        <p>Bénéficiez de l'aide de notre exceptionnelle équipe d'assistance, par chat en direct ou par e-mail, jour et nuit.</p>
                                    </div>
                                </div>
                                <!-- list item -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="integration" class="integration_section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-12 wow fadeFromLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="integration_img">
                            <img src="assets/images/home4.png" alt="">
                        </div>
                    </div>
                    <div class="col-lg-5 wow fadeFromRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <div class="integration_text saas2-headline pera-content">
                            <span class="feature_tag">Nos fonctionnalités </span>
                            <p>
                                Cet outil de gestion tout-en-un dispose de nombreuses fonctionnalités pratiques qui permettent de gagner
                                         du temps au quotidien : gestion articles, gestion stock, devis, factures, bon livraisons, ventes, comptabilité.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

         <Client />

    </div>
</template>

<script>
import AboutContent from '../components/AboutContent.vue';
import Client from '../components/Client.vue';
export default {
    name : "Home",
    components: {
    AboutContent,
    Client
}
}
</script>

<style>

</style>