<template>
  <div>
      <footer id="saas_two_footer" class="saas_two_footer_section relative-position">
        <div class="s2-newslatter_section relative-position">
        </div> 
        <div class="footer_content pera-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-12">
                        <div class="s2_footer_widget clearfix ul-li-block  saas2-headline">
                            <div class="s2_footer_about">
                                <div class="s2-footer_logo">
                                    <img src="assets/images/logo.png" alt="">
                                </div>
                                <div class="footer_about">
                                    Zone industrielle, Sidi Ghanem Marrakech,
                                    Maroc
                                </div>
                                <span>Heures d'ouverture: </span>
                                <p>Lun - Sam: 9 - 7,
                                Dimanche: FERMER</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="s2_footer_widget clearfix ul-li-block saas2-headline">
                            <div class="s2_footer_menu">
                                <h3 class="s2_widget_title">
                                    <span></span>
                                    <i></i>
                                </h3>
                                <ul>
                                    <li><router-link to="/"> ACCUIEIL</router-link></li>
                                    <li><router-link to="/fonctionnalites"> FONCTIONNALITES</router-link></li>
                                    <!-- <li><router-link to="/about"> QUI SOMME NOUS</router-link></li> -->
                                    <li><router-link to="/contact"> CONTACTEZ-NOUS</router-link></li>
                                    <li><router-link to="/blogs"> BLOG</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="s2_footer_widget clearfix ul-li-block saas2-headline">
                            <div class="s2_footer_social">
                                <h3 class="s2_widget_title">
                                    <span>Newsletter</span> 
                                    <i></i>
                                </h3>
                                <p>Inscrivez-vous à la newsletter</p>
                                <form action="#">
                                    <input  name="email" type="email" placeholder="Enter your email address">
                                    <button  type="submit" value="Submit"> <i class="fas fa-paper-plane"></i></button>
                                </form>
                                <a href="https://www.facebook.com/profile.php?id=100084958898975" target="_blank"><i class="fb-bg fab fa-facebook-f "></i></a>
                                <a href="javascript:void(0)"><i class="bh-bg fab fa-linkedin"></i></a>
                                <a href="https://wa.me/0708941018" target="_blank"><i class="tw-bg fab fa-whatsapp"></i></a>
                                <a href="mailto:contact@smartsites.ma" target="_blank"><i class="dr-bg fa fa-envelope"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div class="s2-copyright text-center">2022 © All rights reserved by <a href="javascript:void(0)">Smartsites</a></div>

    <div class="facebook_btn">
        <div class="social_btn">
            <div class="iconss">
                <a href="https://www.facebook.com/profile.php?id=100084958898975" target="_blank"><i class="fab fa-facebook-f" style="color: #3b5999;" aria-hidden="true"></i></a>
            </div>    
        </div>
    </div>
    <div class="instagram_btn">
        <div class="social_btn">
            <div class="iconss">
            <a href="mailto:contact@smartsites.ma" target="_blank"><i class="fa fa-envelope" style="color: #e4405f;"></i></a>
            </div>    
        </div>
    </div>
    <div class="wtsp_btn">
        <div class="social_btn">
            <div class="iconss">
                <a href="https://wa.me/0708941018" target="_blank"><i class="fab fa-whatsapp" style="color: #25d366;"></i></a>
            </div>      
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>

</style>