<template>
    <!-- Start of trusted user ============================================= -->
    <section id="trusted_user" class="trusted_user_section">
        <div class="container">
            <div class="trusted_content">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="trusted_content_img ul-li">
                            <ul>
                                <li><img src="assets/images/clients/sh.PNG" alt=""></li>
                                <li><img src="assets/images/clients/chrono.jpg" alt=""></li>
                                <li><img src="assets/images/clients/insta-store.PNG" alt=""></li>
                                <li><img src="assets/images/clients/solo.webp" alt=""></li>
                                <li><img src="assets/images/clients/vision.webp" alt=""></li>
                                <li><img src="assets/images/clients/moqef.webp" alt=""></li>
                                <li><img src="assets/images/clients/finja.webp" alt=""></li>
                                <li><img src="assets/images/clients/arabastta.webp" alt=""></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="user_content">
                            <div class="section_title">
                                <div class="section_title_text saas-modern-headline pera-content">
                                    <h2>ILS NOUS ONT FAIT CONFIANCE</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Start of trusted user  ============================================= -->
</template>

<script>
export default {
  name: 'Client',
  props: {
    msg: String
  }
}
</script>