<template>
  <div>
      <!-- Start of breadcurmb section
        =============================================  -->
        <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
            <div class="container">
                <div class="breadcurmb-title text-center">
                    <h1>CONTACTEZ-NOUS</h1>
                </div>
                <div class="breadcurmb-item-list text-center ul-li">
                   <ul class="saasio-page-breadcurmb">
                       <li><router-link to="/">ACCUEIL</router-link></li>
                       <li><a href="#"> CONTACTEZ-NOUS</a></li>
                   </ul>
               </div>
           </div>
       </section>
    <!-- End of breadcurmb section
        ============================================= --> 

    <!-- Start of Contact inner page section
        ============================================= -->
        <section id="sa-contact-inner" class="sa-contact-inner-section inner-page-padding">
            <div class="container">
                <div class="saas_two_section_title saas2-headline text-center">
                    <span class="title_tag">CONTACTEZ-NOUS</span>
                    <!-- <h2>CONTACTEZ <span>NOUS </span> </h2> -->
                </div>
                <div class="sa-contact-inner-form">
                    <div class="alert alert-success" v-if="isSuccess">Message envoyé</div>
                    <form @submit.prevent="sendMessage">
                        <div class="row">
                            <div class="col-md-6">
                               <input type="text" v-model="name" required name="name" placeholder="Nom Complet">
                           </div>
                           <div class="col-md-6">
                               <input type="email" required v-model="email" name="email" placeholder="Email">
                           </div>
                           <div class="col-md-6">
                               <input type="text" required v-model="phone" name="phone" placeholder="Télephone">
                           </div>
                           <div class="col-md-6">
                               <!-- <input type="text" name="subject" placeholder="Sujet"> -->
                               <select name="subject" v-model="subject" class="form-control" >
                                    <option value="" selected="selected">Choisir le sujet</option>
                                    <option value="Demmander un essai">Demander un essai</option>
                                    <option value="Demmander des informations">Demander des informinformations</option>
                                    <option value="Demmander un devis">Demander un devis</option>
                               </select>
                           </div>
                           <div class="col-md-12">
                            <textarea name="message" required v-model="message" placeholder="Votre Message ..."></textarea>
                        </div>
                        <div class="sa-contact-inner-btn text-center">
                            <button>Envoyer Message</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <!-- End of Contact inner page section
        =============================================  --> 
  </div>
</template>

<script>
import Vue from 'vue'
export default {
    name : 'Contact',
    data() {
        return {
            name : "",
            subject : "",
            email : "",
            message : "",
            phone : "",
            isSuccess : false,
        }
    },
    methods : {

        sendMessage() {
            const data = new FormData()
            data.append('name', this.name)
            data.append('email', this.email)
            data.append('subject', this.subject)
            data.append('message', this.message)
            data.append('phone', this.phone)
            Vue.axios.post('https://smartsites.ma/mailing.php', data)
            .then((response) => {
                console.log(response.statusText);
                this.isSuccess= true
                this.name = "";
                this.subject = "";
                this.email = "";
                this.message = "";
                this.phone = "";
            }).catch((err) => console.log(err));
            
        },
    }
}
</script>

<style>

</style>