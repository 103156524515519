
import Home from './views/Home.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Fonctionnalite from './views/Fonctionnalite.vue'
import Tarif from './views/Tarif.vue'
import Blog from './views/Blog.vue'
import BlogDetail from './views/BlogDetail.vue'
import BlogDetail2 from './views/BlogDetail2.vue'
import BlogDetail3 from './views/BlogDetail3.vue'
import BlogDetail4 from './views/BlogDetail4.vue'
import BlogDetail5 from './views/BlogDetail5.vue'
import BlogDetail6 from './views/BlogDetail6.vue'
import BlogDetail7 from './views/BlogDetail7.vue'

const routes = [
    {path: "/", component: Home, name: "home"},
    {path: "/about", component: About, name: "about"},
    {path: "/contact", component: Contact, name: "contact"},
    {path: "/fonctionnalites", component: Fonctionnalite, name: "fonctionnalites"},
    {path: "/tarifs", component: Tarif, name: "tarifs"},
    {path: "/blogs", component: Blog, children: [
      {path: ":id", component: BlogDetail, props: true}
    ]},
    {path: "/blogDetail/:id", component: BlogDetail, props: true},
    {path: "/C-est-quoi-un-logiciel-CRM", component: BlogDetail2, props: true},
    {path: "/Comment-il-peut-me-servire-ce-logiciel-CRM", component: BlogDetail3, props: true},
    {path: "/Logiciel-crm-pour-gerer-les-factures-et-les-ventes", component: BlogDetail4, props: true},
    {path: "/Regelements-des-client-et-fournisseurs", component: BlogDetail5, props: true},
    {path: "/Un-logiciel-CRM-et-statistiques", component: BlogDetail6, props: true},
    {path: "/Comment-Un-logiciel-CRM-peut-facilité-mon-travail", component: BlogDetail7, props: true},

    {path: "*", redirect: "/"}
  ]

export default routes